<template>
  <div class='terms-container mt-3'>
    <section>
      <Terms
        title='CONTRATO DE TERMOS DE USO'
        textLink='Tenha acesso aos termos de uso da Ho Fácil Web'
        :link='term.termsOfUseUrl'
      />
    </section>

    <section class='mt-3'>
      <Terms
        title='CONTRATO DE POLÍTICA DE PRIVACIDADE'
        textLink='Tenha acesso a política de privacidade da Ho Fácil Web'
        :link='term.privacyPolicyUrl'
      />
    </section>
    <b-input-group class='mt-2'>
      <b-button variant='success' block @click="$router.go(-1)" class='mb-2 mb-sm-2'>Voltar</b-button>
    </b-input-group>
  </div>
</template>

<script>
import Terms from '@/components/Terms'
import { mapActions } from 'vuex'

export default {
  name: 'Policy',
  components: { Terms },
  computed:{
    term() {
      return this.$store.getters['term/term']
    },
  },
  methods: {
    ...mapActions('term', {
      handleGet: 'handleGet'
    }),
  },
  async mounted() {
    await this.handleGet()
  }
}
</script>

<style scoped>
.terms-container {
  width: 100%;
}
</style>
